/***********************
--TIPOGRAFÍAS GLOBALES--
************************/

$font: "Dosis", sans-serif;
$font-alt: "Dosis", sans-serif;

/* ETIQUETAS */

$size-4xlarge: 2.14rem; // 3rem;
$size-3xlarge: 1.78rem; //2.5rem;
$size-2xlarge: 1.42rem; //2rem;
$size-xlarge: 1.07rem; // 1.5rem;
$size-large: 0.89rem; //1.25rem;
$size-medium-large: 0.8rem; // 1.125rem;
$size-medium: 0.71rem; // 1rem;
$size-small: 0.625rem; // 0.875rem;

/* ETIQUETAS */

$size-4xlarge: 3rem;
$size-3xlarge: 1.75rem;
$size-2xlarge: 1.625rem;
$size-xlarge: 1.5rem;
$size-large: 1.25rem;
$size-medium-large: 1.125rem;
$size-medium: 1rem;
$size-small-medium: 0.875rem;
$size-small: 0.75rem;

/* PESOS */

$black: 900;
$bold: 700;
$medium: 500;
$regular: 400;
$light: 200;
$thin: 100;

/**** ESTILOS ****/

.h0,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.h0 {
  font-family: $font;
  font-style: normal;
  font-weight: $bold;
  font-size: 2.5rem;
}

h1 {
  font-family: $font;
  font-style: normal;
  font-weight: $bold;
  font-size: $size-3xlarge;
}

h2 {
  font-weight: $bold;
  font-size: $size-xlarge;
  font-family: $font;
  font-style: normal;
}

h3 {
  font-weight: $bold;
  font-size: $size-xlarge;
  font-family: $font;
  font-style: normal;
}

h4 {
  font-weight: $bold;
  font-size: $size-large;
  font-family: $font;
  font-style: normal;
}

h5 {
  font-weight: $bold;
  font-size: $size-medium-large;
  font-family: $font;
  font-style: normal;
}

.subtitle {
  font-weight: $medium;
  font-size: $size-medium-large;
  font-family: $font;
}

.label {
  font-weight: $medium !important;
  font-size: $size-small-medium;
  font-family: $font;
}

.body-1 {
  font-weight: $regular;
  font-family: $font;
  font-size: $size-small-medium;
}

.body-2 {
  font-weight: $regular;
  font-family: $font;
  font-size: $size-medium;
}

.caption {
  font-weight: $regular;
  font-family: $font;
  font-size: $size-medium;
}

.bold {
  font-weight: $bold !important;
}

.nav {
  font-weight: $regular;
  font-family: $font;
  font-size: $size-medium-large !important;
}

.nav-up {
  font-weight: $bold;
  font-family: $font;
  font-size: $size-medium;
}

a {
  text-decoration: none;
}

.light {
  font-weight: 200 !important;
}
