/* You can add global styles to this file, and also import other style files */

@import "./theme.scss";
@import "./assets/scss/colors.css";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";

/*******************
--GLOBAL COLORS--
********************/

/** PRIMARY v1.1 **/

/** Classes **/

.icon-modification {
  fill: var(--primary);
  stroke: var(--primary);
}
.white {
  color: var(--neutral) !important;
}

.primary {
  color: var(--primary);
}

.secondary {
  color: var(--secondary);
}

.success {
  color: var(--success);
}

.warning {
  color: var(--warning);
}

.error {
  color: var(--error);
}

.error-color {
  color: var(--error);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body-selected {
  background-color: var(--primary);
}

.mat-calendar-body-in-range::before {
  background-color: #edc62850;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: #f7f9fa;
}

@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  position: relative;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  height: 50px;
  width: 50px;
  border: 6px solid #fafafa;
  border-top-color: var(--primary);
  border-bottom-color: var(--primary);
  border-radius: 100%;
  animation: loading-spinner 1.5s infinite linear;
}

button:focus {
  border-color: #00000000;
  box-shadow: none;
}

.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: var(--secondary);
}
.ngx-mat-timepicker__toggle {
  display: none;
}
.arrows-wrap {
  bottom: -1px !important;
}

.mat-mdc-dialog-container,
.mat-mdc-dialog-container .mdc-dialog__container,
.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 16px;
}

.mat-mdc-form-field-subscript-wrapper {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  height: 0;
}

.mat-mdc-text-field-wrapper {
  background-color: var(--neutral);
  height: 40px !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 6px;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: var(--primary);
}
.mat-mdc-select-value {
  color: var(--primary);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--primary);
}

ngx-mat-timepicker-field.wizard-timepicker .mat-mdc-form-field-icon-suffix {
  align-self: flex-start !important;
}

ngx-mat-timepicker-field.wizard-timepicker .mat-mdc-form-field-infix {
  padding: 8px 0px !important;
}
