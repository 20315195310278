:host,
:root {
  --primary: #199479;
  --primary-50: #e3f2ef;
  --primary-100: #badfd7;
  --primary-200: #8ccabc;
  --primary-300: #5eb4a1;
  --primary-400: #3ca48d;
  --primary-500: var(--primary);
  --primary-600: #168c71;
  --primary-700: #128166;
  --primary-800: #0e775c;
  --primary-900: #086549;
  --primary-A100: #96ffdc;
  --primary-A200: #63ffcb;
  --primary-A400: #30ffb9;
  --primary-A700: #17ffb1;

  --contrast-50: #000000;
  --contrast-100: #000000;
  --contrast-200: #000000;
  --contrast-300: #000000;
  --contrast-400: #000000;
  --contrast-500: #ffffff;
  --contrast-600: #ffffff;
  --contrast-700: #ffffff;
  --contrast-800: #ffffff;
  --contrast-900: #ffffff;
  --contrast-A100: #000000;
  --contrast-A200: #000000;
  --contrast-A400: #000000;
  --contrast-A700: #000000;

  /** Secondary - Secondary v1.0 **/

  --secondary: #edc628;
  --secondary-50: #fdf8e5;
  --secondary-100: #faeebf;
  --secondary-200: #f6e394;
  --secondary-300: #f2d769;
  --secondary-400: #f0cf48;
  --secondary-500: var(--secondary);
  --secondary-600: #ebc024;
  --secondary-700: #e8b91e;
  --secondary-800: #e5b118;
  --secondary-900: #24221c;
  --secondary-A100: #ffffff;
  --secondary-A200: #fff3da;
  --secondary-A400: #ffe3a7;
  --secondary-A700: #ffdb8d;

  --secondary-contrast-50: #000000;
  --secondary-contrast-100: #000000;
  --secondary-contrast-200: #000000;
  --secondary-contrast-300: #000000;
  --secondary-contrast-400: #000000;
  --secondary-contrast-500: #000000;
  --secondary-contrast-600: #000000;
  --secondary-contrast-700: #000000;
  --secondary-contrast-800: #000000;
  --secondary-contrast-900: #000000;
  --secondary-contrast-A100: #000000;
  --secondary-contrast-A200: #000000;
  --secondary-contrast-A400: #000000;
  --secondary-contrast-A700: #000000;

  /** Grayscale Scale - Neutral v0.9 **/

  --neutral: #ffffff;
  --neutral-00: #f8f7f2;
  --neutral-05: #d7d7d7;
  --neutral-10: #a5a5a5;
  --neutral-20: #707070;
  --neutral-40: #393939;
  --neutral-60: #272727;
  --neutral-80: #03110e;

  /** Backgrounds **/

  --background: #fff;
  --background-01: #f8f7f2;
  --background-02: var(--primary);

  /** Events and Alerts **/

  /** Success - OK v1.0 **/

  --success: #30af43;
  --success-medium: #59bf69;
  --success-light: #c1e7c7;

  /** Alerts - Warnings v1.0 **/

  --warning: #fa7921;
  --warning-medium: #ffa965;
  --warning-light: #ffe0ca;

  /** Errors - Errors v1.0 **/

  --error: #eb4747;
  --error-medium: #f17e7e;
  --error-light: #ffcaca;

  /** Thematic **/

  --water-20: #acdaec;
  --water: #73c0de;

  /** Transparencies **/

  /** White Transparencies - v1.0 **/

  --transparent-05: rgba(255, 255, 255, 0.05);
  --transparent-10: rgba(255, 255, 255, 0.1);
  --transparent-20: rgba(255, 255, 255, 0.2);
  --transparent-40: rgba(255, 255, 255, 0.4);
  --transparent-60: rgba(255, 255, 255, 0.6);
  --transparent-80: rgba(255, 255, 255, 0.8);

  /** Dark Transparencies - v1.0 **/

  --transparent-dark-05: rgba(0, 0, 0, 0.05);
  --transparent-dark-10: rgba(0, 0, 0, 0.1);
  --transparent-dark-20: rgba(0, 0, 0, 0.2);
  --transparent-dark-40: rgba(0, 0, 0, 0.4);
  --transparent-dark-60: rgba(0, 0, 0, 0.6);
  --transparent-dark-80: rgba(0, 0, 0, 0.8);

  /** Gradients v0.5 **/

  --gradient-primary: linear-gradient(315deg, #00446f 0%, #0070b5 100%);
  --gradient-secondary: linear-gradient(315deg, #56e39f 0%, #0070b7 100%);
  --gradient-neutral: linear-gradient(
    180deg,
    #5a5a5a 0%,
    rgba(255, 255, 255, 0) 100%
  );
  --gradient-03: linear-gradient(
    170.56deg,
    var(--primary) 62.44%,
    #f8f7f2 154.48%
  );
  --gradient-login: linear-gradient(
    180deg,
    #f8f7f2 0%,
    var(--primary-100) 238.36%,
    var(--primary) 402.23%
  );

  /** Box-Shadow Shadows v1.0 **/

  --shadow-soft: 0px 2px 2px rgba(0, 0, 0, 0.15);
  --shadow-medium: 0px 4px 8px rgba(0, 0, 0, 0.15);
  --shadow-hard: 0px 8px 16px rgba(0, 0, 0, 0.2);

  /** Applications **/

  /** Texts **/

  --text-light: var(--neutral-40);
  --text-medium: var(--neutral-60);
  --text-dark: var(--neutral-80);
  --heading: var(--primary-500);
  --link: var(--primary-600);

  /** Colors **/

  --icon-color: var(--primary);
  --icon-neutral: var(--neutral-40);
  --icon-dark-color: var(--neutral-40);
  --icon-dark-background: var(--neutral-80);
  --icon-dark-neutral: var(--neutral-05);
}
